@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/arrow-down.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/options.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/menu-cake.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/home.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/color-bucket.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/feed.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/board.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/bell.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/shield.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/toggle-off.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/close.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/check-o.css');
/*profile */
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/ghost-character.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/ui-kit.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/search.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/home-alt.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/log-out.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/color-bucket.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/add.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/smartphone-chip.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::selection {
  color:white;
  background: rgb(116, 116, 116);
  }
#root{
  overflow-x: clip
}
.autofilinputred:-webkit-autofill{
  -webkit-text-fill-color: rgb(220, 38, 38)
}
.EmptyCard-Opacity{
  filter: opacity(0.4);
}
.waiting-form{
  filter: contrast(0.8) opacity(0.4);
}
.PropertyCard{
  transition: background 1s ease-in-out;
}
.out {
  width: 200px;
  height: 100% ;
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 35px;
  opacity: 60%;
}

.in {
  width: 400px;
  height: 400px;
  position: absolute;
  bottom: 220px;
  left: -7px;
  border-radius: 50%;
  box-shadow: 0 0 0 300px white; /* this is the white background */
  /*background-color: hsla(0, 100%, 50%, 0.158);*/
}
@media (max-width: 900px){  
  .mainOI::after{
  content: '';
  display: block;
  width: 100px;
  height: 400px;
  background-color: white;
  border-top-right-radius: 40px;
  position: absolute;
  top:0;
  left: -100px;
  opacity: 60%;
}}

#content-blur{
  backdrop-filter: blur(8px);
}
.options-profile{
  transition: width ease-in-out 300ms;
}
#promt_M{
  background-position: center;
  background-size: cover;
  filter: opacity(0.9);
  backdrop-filter: blur(6px);
}
#promt{
  /*box-shadow: 0px 0px 20px 1px black;*/
  
  backdrop-filter: blur(20px);
  background-color: #161616;
  opacity: 0.9;
  background: radial-gradient(circle, transparent 20%, #161616 20%, #161616 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #161616 20%, #161616 80%, transparent 80%, transparent) 32.5px 32.5px, linear-gradient(#000000 2.6px, transparent 2.6px) 0 -1.3px, linear-gradient(90deg, #000000 2.6px, #161616 2.6px) -1.3px 0;
  background-size: 65px 65px, 65px 65px, 32.5px 32.5px, 32.5px 32.5px;
}

#white-container::after{
  content: '';
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100px;
  top: 0;
  transform: translateY(-100%);/*a1:rounded-tr-[40px] rounded-tr-[0px]*/
}
@media (min-width: 900px) {
  #white-container::after{
    border-top-right-radius: 40px;
  }
}
#text-login{
  writing-mode: vertical-rl
}
#text-register{
  writing-mode: vertical-rl
}
#text-firstInfo{
  writing-mode: vertical-rl
}
.color-black{
  filter: invert(100%) sepia(100%) saturate(100%) brightness(0%) contrast(100%);
}
.color-white{
  filter: invert(100%) sepia(93%) saturate(1%) hue-rotate(257deg) brightness(108%) contrast(100%);
}
.navigation{
 transform: translateX(-600px);
}

@media only screen and (max-width: 649px) {
  .navigation{
    transform: translateX(-100%);
   }
}

html {
  --scrollbarBG: #F6F7F8;
  --thumbBG: #d9d9d9;
}
#menu-slide-container::-webkit-scrollbar{
  height: 3px;

}
#menu-slide-container::-webkit-scrollbar-track{
  background: var(--scrollbarBG);

}
#menu-slide-container::-webkit-scrollbar-thumb{
  background-color: var(--thumbBG) ;
  border-radius: 2px;
  
}

body::-webkit-scrollbar {
  width: 11px;
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.properties{
  height: 100%;
  padding-bottom: 100px;
}
.propertiesP{
  height: auto;
}

.levitation {
  animation: bouge 8s infinite alternate-reverse;
  
}
.levitationS {
  animation: bouge 8s infinite alternate-reverse;
  animation-delay: -4s;
}
.reactlogo{
  animation: rotate_infinite 8s infinite;
}

#scrollBTN p{
  animation: hiddenBTN 3s infinite;
  animation-delay: -6s;
}
#scrollBTN i{
  animation: transformArrow 3s infinite;
  animation-delay: -6s;
}
@keyframes hiddenBTN {
  0%{
    opacity: 1;
  }
  30%{
    opacity: 0;
  }
  70%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@keyframes transformArrow {
  0%{
    opacity: 0;
    top: 0;
  }
  25%{
    opacity: 0;
    top: 0;
    
  }
  30%{
    opacity: 1;
  }
  60%{
    opacity: 0;
    top: 60px;
  }
  100%{
    opacity: 0;
    top: 0;
  }
}

@keyframes rotate_infinite {
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

@keyframes bouge {
  /*starting point and angle (same angle as choosen into .levitation)*/
  from {
    transform: translateY(-50px);
  }
  /* ending point and angle */
  to {
    transform: translateY(100px);
  }
}